import { FC, PropsWithChildren } from "react"
import { Text } from "~/components/ui"
import { layoutRecipe } from "./layout.recipe"

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  const classes = layoutRecipe({})

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <img
          src="/logo.png"
          height="35px"
          width="160px"
          className={classes.headerSideItem}
        />
        <Text className={classes.headerTitle}>
          ライフプランシミュレーション
        </Text>
        <div className={classes.headerSideItem}></div>
      </div>
      <div className={classes.body}>{children}</div>
    </div>
  )
}
