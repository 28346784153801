import { sva } from "styled-system/css"

export const layoutRecipe = sva({
  slots: ["root", "header", "headerSideItem", "headerTitle", "body"],
  base: {
    root: {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
      height: "100vh",
      width: "100vw",
      background: "#F4F4F4",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "80px",
      width: "full",
      paddingX: "36px",
      paddingY: "16px",
      background: "#FFFFFF",
      borderBottom: "1px solid #E7E7E7",
    },
    headerTitle: {
      color: "#1A1A1A",
      fontFamily: "Noto Sans JP",
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "144%",
      letterSpacing: "1.2px",
    },
    headerSideItem: {
      height: "35px",
      width: "160px",
    },
    body: {
      flex: 1,
    },
  },
})

export type LayoutVariants = ReturnType<typeof layoutRecipe.getVariantProps>
